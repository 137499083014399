import React from 'react'
import T from 'prop-types'
import {Body} from 'Modules'
import SEO from 'Layout/SEO'

export const LegalPageTemplate = ({source}) => (
  <Body source={source}/>
)

LegalPageTemplate.propTypes = {source: T.string}

export const LegalPage = props => (
  <>
    <SEO {...props.data.markdownRemark.frontmatter.seo} {...props}/>
    <LegalPageTemplate source={props.data.markdownRemark.rawMarkdownBody}/>
  </>
)

LegalPage.propTypes = {
  data: T.shape({
    markdownRemark: T.shape({
      rawMarkdownBody: T.string.isRequired,
      frontmatter: T.shape({
        seo: T.object.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}

export default LegalPage
