import LegalPage from './LegalPage'
import {graphql} from 'gatsby'

export default LegalPage

export const pageQuery = graphql`
  query LegalPageQuery {
    markdownRemark(frontmatter: {templateKey: {eq: "legal-page"}}) {
      rawMarkdownBody
      frontmatter {
        seo {
          title
          description
        }
      }
    }
  }
`
